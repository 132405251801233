
/* Slider */
#slider{
  width:100%;
  height:600px;
  position:relative;
  overflow:hidden;
}
@keyframes load{
  from{left:100%;}
  to{left:0;}
}
.slides{
  width:400%;
  height:100%;
  position:relative;
  -webkit-animation:slide 30s infinite;
  -moz-animation:slide 30s infinite;
  animation:slide 30s infinite;
}
.slider{
  width:25%;
  height:100%;
  float:left;
  position:relative;
  z-index:1;
  overflow:hidden;
}
.image{
  width:100%;
  height:100%;
}
.image img{
  width:100%;
  height:600px;
}



/* Contents */
.content{
  width:100%;
  height:100%;
  position:absolute;
  overflow:hidden;
}
.content-txt{
  width:400px;
  height:350px;
  float:left;
  position:relative;
  top:80px;
  -webkit-animation:content-s 7.5s infinite;
  -moz-animation:content-s 7.5s infinite;
  animation:content-s 7.5s infinite;
}
.content-txt h1{
  text-transform:uppercase;
  font-size:44px;
  color:#fff;
  margin-left:30px;
}
.content-txt h2{
  font-weight:normal;
  font-size:14px;
  color:#fff;
  text-align:left;
  margin-left:30px;
}
.btn-box{
    padding-top: 20px;
    margin-left:30px;
}


/* Animation */

@keyframes slide{
    0%,100%{
        margin-left:0%;
      }
      21%{
        margin-left:0%;
      }
      25%{
        margin-left:-100%;
      }
      46%{
        margin-left:-100%;
      }
      50%{
        margin-left:-200%;
      }
      71%{
        margin-left:-200%;
      }
      75%{
        margin-left:-300%;
      }
      96%{
        margin-left:-300%;
      }
    }


@keyframes content-s{
    0%{left:-420px;}
  10%{left:0px;}
  30%{left:0px;}
  40%{left:0px;}
  50%{left:0px;}
  60%{left:0px;}
  70%{left:0;}
  80%{left:-420px;}
  90%{left:-420px;}
  100%{left:-420px;}
  }

.banner-subtitle {
    font-size: 30px;
    color: #c1862b !important;
    text-align:left;
    margin-left:30px;
    padding-bottom:10px;
}
.play-button {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #e9212e;
    text-align: center;
}
.slide-style2-button {
    position: relative;
    display: inline-block;
    margin-left: 25px;
    color: #ffffff;
    font-size: 15px;
    line-height: 70px;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
    transition: all 500ms ease;
}
.btn-box a.play-button span:before {
    position: relative;
    display: block;
    color: #ffffff;
    font-size: 30px;
    line-height: 70px;
}
.icon-next:before {
    content: "\e910";
}
.btn-box a.slide-style2-button:before {
    position: absolute;
    left: 0;
    bottom: 25px;
    right: 0;
    height: 2px;
    background: #ffffff;
    content: "";
}
.banner{
    position: absolute;
    top: 0px;
    left: 0px; 
    z-index: 0; 
    width: 30%; 
    height: 100%; 
    transform: matrix(1, 0, 0, 1, 0, 0); 
    opacity: 1; 
    background-Color: rgb(41, 43, 47);
    display: flex;
}
@media only screen and (max-width: 767px){
    .banner {
        width: 100% !important;
        background-Color: transparent !important;
    }
}