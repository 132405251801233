        #about .logo-box-style2 a img {
            width: 102px !important;
            height: 118px !important;
        }

        #about .top-bar-style2 {
            position: relative;
            display: block;
            background: #26272b;
            padding: 20px 26px !important;
        }

        #about .sec-title .title3 {
            position: relative;
            display: block;
            color: #000;
            font-size: 35px;
            line-height: 44px;
            font-weight: 700;
            text-transform: uppercase;
            font-family: 'Rubik', sans-serif !important;
        }

        #about .main-project-area.style4 {
            background-color: #5d6d7b !important;
        }

        #about .sec-title p {
            color: #e9212e;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            text-transform: uppercase;
            margin: 0 0 7px;
            font-family: 'Rubik', sans-serif;
        }

        #about .sec-title .title {
            position: relative;
            display: block;
            color: #27282c;
            font-size: 35px;
            line-height: 44px;
            font-weight: 700;
            text-transform: uppercase;
            font-family: 'Rubik', sans-serif;
            text-align: center;
        }

        #about .about-role {
            color: #e9212e;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            text-transform: uppercase;
            margin: 0 0 7px;
            font-family: 'Rubik', sans-serif;
        }

        #about .fact-counter {
            padding-top: 60px !important;
        }

        #about .slogan-row {
            border: 2px solid #000;
            padding: 20px 0 20px 0;
            margin: 30px 0 0 0;
            background-color: #24334a;
            padding: 10px 0 30px 0;
        }

        #about .slogan-style2-area {
            padding: 30px 0 12px !important;
            margin-left: auto;
            margin-right: auto;
            border-bottom: 2px solid #fff;
            width: 200px !important;
        }

        #about .sec-title .title1 {
            position: relative;
            display: block;
            color: #fff;
            font-size: 35px;
            line-height: 44px;
            font-weight: 700;
            text-transform: uppercase;
            font-family: 'Rubik', sans-serif;
            text-align: center;
        }

        #about .company-overview-area {
            padding: 105px 0 30px !important;
            background-color: #5d6d7b;
        }

        #about .company-overview-area:before {
            background-color: #5d6d7b !important;
        }

        #about .sec-title .bottom-text {
            font-size: 16px !important;
            text-transform: uppercase !important;
            color: #c1862b !important;
        }

        #about .single-fact-counter .count-box h1 {
            color: #c1862b !important;
        }

        #about .single-fact-counter .title h3 {
            color: #e9212e !important;
        }

        #about .intro-box .text {
            padding: 30px;
        }

        #about .intro-box .text p {
            text-align: justify !important;
            color: #000 !important;
        }

        #about .about-moov {
            padding-bottom: 22px;
            color: #fff !important;
            text-transform: uppercase;
        }

        #about .about-bg {
            background: url(../../images/about-bg.webp);
            background-attachment: scroll;
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .car-animation {
            position: relative;
            z-index: 10;
        }

        .animated-van {
            position: absolute;
            top: -77px;
            left: -300px;
            animation: moveAcross 15s ease-in-out infinite;
            animation-play-state: running;
        }

        @keyframes moveAcross {
            0% {
                left: -300px;
            }

            100% {
                left: 100%;
            }
        }