        #sitemap .breadcrumb-section .page-tag {
            font-size: 30px;
        }
        #sitemap .main-btn.main-btn-2 {
            border: 2px solid #ffaa17;
            background-color: transparent;
        }
        #sitemap .main-btn.main-btn-2:hover {
            border: 2px solid #1d2122;
        }
        #sitemap .mt-40 {
            margin-top: 40px;
        }
        #sitemap .main-btn {
            font-family: 'Rubik', sans-serif;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 700;
            padding: 0 45px;
            background-color: #ffaa17;
            color: #1d2122;
            line-height: 60px;
            height: 60px;
            -webkit-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s;
        }
        #sitemap .main-btn:hover{
            background-color: #1d2122;
            color: #fff;
        }
        #sitemap .breadcrumb-section .page-tag {
            font-family: 'Rubik', sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            color: #ffaa17;
        }
        #sitemap .breadcrumb-section .page-title {
            font-size: 60px;
            line-height: 1.1;
            color: #fff;
            text-transform: uppercase;
            letter-spacing: -1px;
        }
        #sitemap .section-title{
            visibility: visible !important;
            opacity: 1 !important;
            padding: 0 0 0 100px !important;
            position: relative !important;
            left: 0 !important;
            bottom: 0 !important;
            right: 0 !important;
        }
        #sitemap .title-tag{
            font-family: "Rajdhani", sans-serif !important;
            font-weight: 700 !important;
            font-size: 60px !important;
            letter-spacing: -0.03em !important;
            color: #ffaa17 !important;
            line-height: 1 !important;
            position: absolute !important;
            left: 0 !important;
            top: 0 !important;
        }
        #sitemap .line{
            background-color: #1d2122 !important;
            height: 1px !important;
            width: 170px !important;
            margin-top: 20px !important;
            margin-bottom: 20px !important;
            display: block !important;
        }
        #sitemap .title1{
            font-size: 30px !important;
            letter-spacing: -0.03em !important;
            margin-bottom: 15px !important;
            text-transform: uppercase !important;
        }
        #sitemap .logo-box-style2 a img {
            width: 102px !important;
            height: 118px !important;
        }
        #sitemap .top-bar-style2 {
            position: relative;
            display: block;
            background: #26272b;
            padding: 20px 26px !important;
        }
        @media (min-width: 1200px){
            #sitemap .col-xl-3 {
                flex: 0 0 35%;
                max-width: 30%;
            }
            #sitemap .col-xl-9 {
                flex: 0 0 65%;
                max-width: 65%;
            }
            #sitemap .col-xl-2{
                flex: 0 0 33.666667%;
                max-width: 30.666667%;
            }
        }
        #sitemap .single-service-style1 .overlay-content-box .icon span:before{
            display: inline-block;
            color: #c1862b !important;
            font-size: 48px !important;
            line-height: 48px !important;
        }
        #sitemap .single-service-style1 .text-holder .top .icon span:before{
            color: #d29929 !important;
            font-size: 30px !important;
            line-height: 40px !important;
        }
        #sitemap .single-service-style1 .text-holder .bottom .title h3{
            color: #27282c;
            font-weight: 600;
            text-align: center;
        }
        #sitemap .single-service-style1 .text-holder{
            padding: 35px 0px 28px !important;
        }
        #sitemap .single-service-style1 .text-holder .bottom{
            padding-top: 7px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        #sitemap .single-service-style1 .text-holder .overlay-content{
            padding: 6px 0 0 !important;
        }
        #sitemap .single-blog-post .top-text-box{
            padding: 20px 0 0 0;
        }
        #sitemap .blog-single-bottom-content-box{
            margin: 22px 0 30px 0 !important;
        }
        #sitemap .single-sidebar .categories li a{
            line-height: 38px;
        }
        #sitemap .fade:not(.show) {
            opacity: 0;
            display: none;
        }
        #sitemap .line-footer {
            background-color: #1d2122 !important;
            height: 1px !important;
            width: 100%;
            margin-top: 20px !important;
            margin-bottom: 24px !important;
            display: block !important;
        }
        #sitemap .portfolio-container2 {
            visibility: visible;
            opacity: 1;
            padding: 0 0 0 0;
            position: relative;
            left: 0;
            bottom: 0;
            right: 0;
            text-align: end;
        }
        #sitemap .blog-single-bottom-content-box a {
            color: #828282;
            text-decoration: none;
            background-color: transparent;
        }
        #sitemap .sitemap-bg{
            background: url(../../images/our-products.webp) no-repeat;
        }
        #sitemap .breadcrumb-section .container {
            padding-top: 250px;
            padding-bottom: 150px;
            position: relative;
        }