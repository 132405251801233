    #sector .portfolio-items p {
        text-align: justify;
    }

    #sector .breadcrumb-section .page-tag {
        font-size: 30px;
    }

    #sector .section-title {
        visibility: visible !important;
        opacity: 1 !important;
        padding: 0 0 0 100px !important;
        position: relative !important;
        left: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
    }

    #sector .title-tag {
        font-family: "Rajdhani", sans-serif !important;
        font-weight: 700 !important;
        font-size: 60px !important;
        letter-spacing: -0.03em !important;
        color: #ffaa17 !important;
        line-height: 1 !important;
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
    }

    #sector .line {
        background-color: #1d2122 !important;
        height: 1px !important;
        width: 170px !important;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        display: block !important;
    }

    #sector .title1 {
        font-size: 30px !important;
        letter-spacing: -0.03em !important;
        margin-bottom: 15px !important;
        text-transform: uppercase !important;
    }

    #sector .logo-box-style2 a img {
        width: 102px !important;
        height: 118px !important;
    }

    #sector .top-bar-style2 {
        position: relative;
        display: block;
        background: #26272b;
        padding: 20px 26px !important;
    }

    #sector .sector-image1 {
        background: url(../../images/architectural-acoustics.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 570px;
    }

    #sector .sector-image2 {
        background: url(../../images/auditorium-acoustics.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 570px;
    }

    #sector .sector-image3 {
        background: url(../../images/theme-park-acoustics.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 570px;
    }

    #sector .sector-image4 {
        background: url(../../images/skynews1.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 330px;
    }

    #sector .sector-image5 {
        background: url(../../images/education-acoustics.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 570px;
    }

    #sector .sector-image6 {
        background: url(../../images/home-theatre-acoustics.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 570px;
    }

    #sector .sector-image7 {
        background: url(../../images/Vox-Sharjah2.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 570px;
    }

    #sector .sector-image8 {
        background: url(../../images/auditorium-seating-solution.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 570px;
    }

    #sector .sector-image9 {
        background: url(../../images/whotel.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 570px;
    }

    #sector .sector-image10{
        background: url(../../images/commercial-acoustics.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 570px;
    }

    #sector .sector-image11{
        background: url(../../images/wall-and-floor.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 570px;
    }

    #sector .sector-image12{
        background: url(../../images/emal2.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 570px;
    }

    #sector .sector-image13{
        background: url(../../images/africa-hall-project4.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 450px;
    }
    #sector .sector-image14{
        background: url(../../images/american-school2.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 450px;
    }
    #sector .sector-image15{
        background: url(../../images/skynews2.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 300px;
    }
    #sector .sector-image16{
        background: url(../../images//Sector_acoustic.jpeg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 570px;
        height: 500px;
    }

    #sector .sector-bg {
        background: url(../../images/business-sector.webp) no-repeat;
    }

    #sector .sector-acousticwall {
        background: url(../../images/wall-and-floor.webp) no-repeat;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 570px;
    }

    #sector .sector-architectural {
        background: url(../../images/architectural-acoustics.webp) no-repeat;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 570px;
    }

    #sector .sector-auditorium {
        background: url(../../images/auditorium-acoustics.webp) no-repeat;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 570px;
    }

    #sector .sector-commercial {
        background: url(../../images/commercial-acoustics.webp) no-repeat;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 570px;
    }

    #sector .sector-education {
        background: url(../../images/education-acoustics.webp) no-repeat;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 570px;
    }

    #sector .sector-entertainment {
        background: url(../../images/Vox-Sharjah-moov-group.webp) no-repeat;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 570px;
    }

    #sector .sector-homecinema {
        background: url(../../images/home-theatre-acoustics.webp) no-repeat;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 570px;
    }

    #sector .sector-hospitality {
        background: url(../../images/hospitality-acoustics.webp) no-repeat;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 570px;
    }

    #sector .sector-industrial {
        background: url(../../images/industrial-acoustics.webp) no-repeat;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 570px;
    }

    #sector .sector-seating {
        background: url(../../images/auditorium-seating-solution.webp) no-repeat;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 570px;
    }

    #sector .sector-studio {
        background: url(../../images/studio-acoustics.webp) no-repeat;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 570px;
    }

    #sector .sector-themepark {
        background: url(../../images/theme-park-acoustics.webp) no-repeat;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 570px;
    }

    @media (min-width: 1200px) {
        #sector .col-xl-3 {
            flex: 0 0 35%;
            max-width: 30%;
        }

        #sector .col-xl-9 {
            flex: 0 0 65%;
            max-width: 65%;
        }

        #sector .col-xl-2 {
            flex: 0 0 33.666667%;
            max-width: 30.666667%;
        }
    }

    #sector .single-service-style1 .overlay-content-box .icon span:before {
        display: inline-block;
        color: #c1862b !important;
        font-size: 48px !important;
        line-height: 48px !important;
    }

    #sector .single-service-style1 .text-holder .top .icon span:before {
        color: #d29929 !important;
        font-size: 30px !important;
        line-height: 40px !important;
    }

    #sector .single-service-style1 .text-holder .bottom .title h3 {
        color: #27282c;
        font-weight: 600;
        text-align: center;
    }

    #sector .single-service-style1 .text-holder {
        padding: 35px 0px 28px !important;
    }

    #sector .single-service-style1 .text-holder .bottom {
        padding-top: 7px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    #sector .single-service-style1 .text-holder .overlay-content {
        padding: 6px 0 0 !important;
    }

    #sector .single-blog-post .top-text-box {
        padding: 20px 0 0 0;
    }

    #sector .blog-single-bottom-content-box {
        margin: 22px 0 30px 0 !important;
    }

    #sector .single-sidebar .categories li a {
        line-height: 38px;
    }

    #sector .fade:not(.show) {
        opacity: 0;
        display: none;
    }

    #sector .line-footer {
        background-color: #1d2122 !important;
        height: 1px !important;
        width: 100%;
        margin-top: 20px !important;
        margin-bottom: 24px !important;
        display: block !important;
    }

    #sector .portfolio-container2 {
        visibility: visible;
        opacity: 1;
        padding: 0 0 0 0;
        position: relative;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: end;
    }

    #sector li.nav-item {
        padding: 20px 0 20px 0;
    }

    #sector .recently-project-style2-area {
        background-color: #24334a !important;
    }

    #sector .sec-title .pro-title {
        position: relative;
        display: block;
        color: #fff;
        font-size: 35px;
        line-height: 44px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: 'Rubik', sans-serif;
    }

    #sector .sec-title .bottom-text {
        font-size: 16px !important;
        text-transform: uppercase !important;
        color: #c1862b !important;
    }

    #sector .single-project-style2 .img-holder {
        border: 2px solid #fff;
    }

    #sector .single-project-style2 .title-box {
        position: absolute;
        left: 10px;
        bottom: 34px;
        z-index: 3;
        background-color: #00000078;
        width: 100%;
        padding: 20px;
    }

    #sector .single-project-style2 .title-box h3 {
        color: #fff !important;
        text-transform: uppercase;
        font-size: 14px;
    }

    #sector .single-project-style2 .title-box span {
        color: #c1862b !important;
        text-transform: uppercase;
        font-size: 12px;
    }

    #sector .single-project-style2 .title-box h4 {
        color: #fff !important;
        text-transform: uppercase;
        font-size: 12px;
    }