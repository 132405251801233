#contact .logo-box-style2 a img {
    width: 102px !important;
    height: 118px !important;
}
#contact .top-bar-style2 {
    position: relative;
    display: block;
    background: #26272b;
    padding: 20px 26px !important;
}
@media (min-width: 1200px){
    #contact .offset-xl-1 {
        margin-left: 18px !important;
    }
}
#contact .con-inner {
   padding-bottom: 100px;
}
#contact .con-inner-top {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
#contact .con-inner-bot {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}
#contact .con-inner-box {
    width: 26%;
    text-align: center;
    color: #ffffff;
    margin: 30px 0px 30px 10px;
}
#contact .con-inner-box-top {
    background: #5d6d7b !important;
    padding: 20px;
}
#contact .con-inner-box-top h3 {
    color: #ffffff !important;
    font-size: 16px !important;
    text-transform: uppercase !important;
}
#contact .addcol {
    color: #c1862b !important;
}
#contact .con-inner-box-bot {
    background: #e9212e;
    padding: 20px;
}
#contact .con-inner-box-bot p {
    margin-bottom: 0px;
    font-size: 13px;
}
#contact .contact-address-area {
    position: relative;
    display: block;
    background: #f6f6f6;
    padding-bottom: 10px;
    z-index: 100;
}
#contact .contact-info-area{
    padding: 10px 0 10px;
}
@media only screen and (max-width: 767px){
    #contact .con-inner-top {
        flex-direction: column;
    }
    #contact .con-inner-box{
        width: 100%;
        margin: 30px 0px;
    }
}
span.icon-global{
    font-size: 40px;
}
#contact .contact-bg{
    background: url(../../images/recent-event.webp) no-repeat;
    background-attachment: scroll;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
}
#contact .info-bg{
    background:url(../../images/Dubai.webp) no-repeat;
}