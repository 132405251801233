    #echo-eliminator .breadcrumb-section .page-tag {
        font-size: 30px;
    }
    #echo-eliminator .section-title{
        visibility: visible !important;
        opacity: 1 !important;
        padding: 0 0 0 100px !important;
        position: relative !important;
        left: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
    }
    #echo-eliminator .title-tag{
        font-family: "Rajdhani", sans-serif !important;
        font-weight: 700 !important;
        font-size: 60px !important;
        letter-spacing: -0.03em !important;
        color: #ffaa17 !important;
        line-height: 1 !important;
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
    }
    #echo-eliminator .line{
        background-color: #1d2122 !important;
        height: 1px !important;
        width: 170px !important;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        display: block !important;
    }
    #echo-eliminator .title1{
        font-size: 30px !important;
        letter-spacing: -0.03em !important;
        margin-bottom: 15px !important;
        text-transform: uppercase !important;
    }
    #echo-eliminator .logo-box-style2 a img {
        width: 102px !important;
        height: 118px !important;
    }
    #echo-eliminator .top-bar-style2 {
        position: relative;
        display: block;
        background: #26272b;
        padding: 20px 26px !important;
    }
    @media (min-width: 1200px){
        #echo-eliminator .col-xl-3 {
            flex: 0 0 35%;
            max-width: 30%;
        }
        #echo-eliminator .col-xl-9 {
            flex: 0 0 65%;
            max-width: 65%;
        }
        #echo-eliminator .col-xl-2{
            flex: 0 0 33.666667%;
            max-width: 30.666667%;
        }
    }
    #echo-eliminator .single-service-style1 .overlay-content-box .icon span:before{
        display: inline-block;
        color: #c1862b !important;
        font-size: 48px !important;
        line-height: 48px !important;
    }
    #echo-eliminator .single-service-style1 .text-holder .top .icon span:before{
        color: #d29929 !important;
        font-size: 30px !important;
        line-height: 40px !important;
    }
    #echo-eliminator .single-service-style1 .text-holder .bottom .title h3{
        color: #27282c;
        font-weight: 600;
        text-align: center;
    }
    #echo-eliminator .single-service-style1 .text-holder{
        padding: 35px 0px 28px !important;
    }
    #echo-eliminator .single-service-style1 .text-holder .bottom{
        padding-top: 7px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    #echo-eliminator .single-service-style1 .text-holder .overlay-content{
        padding: 6px 0 0 !important;
    }
    #echo-eliminator .single-blog-post .top-text-box{
        padding: 20px 0 0 0;
    }
    #echo-eliminator .blog-single-bottom-content-box{
        margin: 22px 0 30px 0 !important;
    }
    #echo-eliminator .single-sidebar .categories li a{
        line-height: 38px;
    }
    #echo-eliminator .product6 {
        background-image: url(../../images/echo-eliminator-acoustic-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #echo-eliminator .product6-sub1{
        background-image: url(../../images/Echo-Eliminator-four-4-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #echo-eliminator .product6-sub2{
        background-image: url(../../images/Echo-Eliminator-nineteen-3-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #echo-eliminator .product6-sub3{
        background-image: url(../../images/Echo-Eliminator-seventeen-1-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #echo-eliminator .product6-sub4{
        background-image: url(../../images/Echo-eliminator-sixteen-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #echo-eliminator .product6-sub5{
        background-image: url(../../images/Echo-Eliminator-ten-2-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #echo-eliminator .product6-sub6{
        background-image: url(../../images/Echo-Eliminator-three-2-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #echo-eliminator .product6-sub7{
        background-image: url(../../images/Echo-Eliminator-twelve-2-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #echo-eliminator .product6-sub8{
        background-image: url(../../images/Echo-Eliminator-twenty-five-1-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #echo-eliminator .product6-sub9{
        background-image: url(../../images/black_up_lg-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #echo-eliminator .product6-sub10{
        background-image: url(../../images/burg_up_lg1-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #echo-eliminator .product6-sub11{
        background-image: url(../../images/graphite_up_lg-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #echo-eliminator .product6-sub12{
        background-image: url(../../images/green_up_lg1-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #echo-eliminator .product6-sub13{
        background-image: url(../../images/light_gray_up_lg-1-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #echo-eliminator .product6-sub14{
        background-image: url(../../images/marble_up_lg-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #echo-eliminator .fade:not(.show) {
        opacity: 0;
        display: none;
    }
    #echo-eliminator .line-footer {
        background-color: #1d2122 !important;
        height: 1px !important;
        width: 100%;
        margin-top: 20px !important;
        margin-bottom: 24px !important;
        display: block !important;
    }
    #echo-eliminator .portfolio-container2 {
        visibility: visible;
        opacity: 1;
        padding: 0 0 0 0;
        position: relative;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: end;
    }
    #echo-eliminator .echoeliminator-bg{
        background: url(../../images/our-products.webp) no-repeat;
    }