@media only screen and (min-width: 1200px) and (max-width: 1380px){
    .header-style2 .inner-container {
        background-color: #5d6d7b !important;
        }
    }
    .header-style2.stricky-fixed {
        background: #5d6d7b !important;
    }
    .main-menu .navigation > li > a {
        color: #ffffff !important;
    }
    header.main-header.header-style2.stricky {
        background-color: #5d6d7b !important;
    }
    .elinks{
        color: #fff;
        font-size: 14px;
    }
    .elinks:hover {
        color: #e9212e;
    }
    .moov-logo {
        background: url(../images/moovgroup.webp) no-repeat;
        width: 150px;
        height: 150px;
        background-size: 150px;
    }
    .logo-box-style2 a img {
        width: 116px !important;
        height: 136px !important;
    }