    #product .breadcrumb-section .page-tag{
        font-size: 30px;
    }
    #product .section-title{
        visibility: visible !important;
        opacity: 1 !important;
        padding: 0 0 0 100px !important;
        position: relative !important;
        left: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
    }
    #product .title-tag{
        font-family: "Rajdhani", sans-serif !important;
        font-weight: 700 !important;
        font-size: 60px !important;
        letter-spacing: -0.03em !important;
        color: #ffaa17 !important;
        line-height: 1 !important;
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
    }
    #product .line{
        background-color: #1d2122 !important;
        height: 1px !important;
        width: 170px !important;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        display: block !important;
    }
    #product .title1{
        font-size: 30px !important;
        letter-spacing: -0.03em !important;
        margin-bottom: 15px !important;
        text-transform: uppercase !important;
    }
    #product .logo-box-style2 a img {
        width: 102px !important;
        height: 118px !important;
    }
    #product .top-bar-style2 {
        position: relative;
        display: block;
        background: #26272b;
        padding: 20px 26px !important;
    }
    @media (min-width: 1200px){
        #product .col-xl-3 {
            flex: 0 0 35%;
            max-width: 30%;
        }
        #product .col-xl-9 {
            flex: 0 0 65%;
            max-width: 65%;
        }
        #product .col-xl-2{
            flex: 0 0 33.666667%;
            max-width: 30.666667%;
        }
    }
    #product .single-service-style1 .overlay-content-box .icon span:before{
        display: inline-block;
        color: #c1862b !important;
        font-size: 48px !important;
        line-height: 48px !important;
    }
    #product .single-service-style1 .text-holder .top .icon span:before{
        color: #d29929 !important;
        font-size: 30px !important;
        line-height: 40px !important;
    }
    #product .single-service-style1 .text-holder .bottom .title h3{
        color: #27282c;
        font-weight: 600;
        text-align: center;
    }
    #product .single-service-style1 .text-holder{
        padding: 35px 0px 28px !important;
    }
    #product .single-service-style1 .text-holder .bottom{
        padding-top: 7px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    #product .single-service-style1 .text-holder .overlay-content{
        padding: 6px 0 0 !important;
    }
    #product .single-blog-post .top-text-box{
        padding: 20px 0 0 0;
    }
    #product .blog-single-bottom-content-box{
        margin: 22px 0 30px 0 !important;
    }
    #product .single-sidebar .categories li a{
        line-height: 38px;
    }
    #product .specs{
        font-weight: 500;
        position: absolute;
        left: 150px;
    }
    #product .li-specs{
        padding-bottom: 50px;
        border-bottom: 1px solid #5d6d7b;
    }
    #product .product1 {
        background: url(../../images/fabrisorb.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #product .product-sub1 {
        background: url(../../images/fabrisorb-acoustic-wall-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product-sub2 {
        background: url(../../images/fabrisorb-acoustic-clouds.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product-sub3 {
        background: url(../../images/fabrisorb-acoustic-baffles.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product-sub4 {
        background: url(../../images/fabrisorb-acoustic-designer-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product2 {
        background: url(../../images/fabrisorb-deco-acoustic-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #product .product2-sub1 {
        background: url(../../images/fabrisorbdeco-wall-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product2-sub2 {
        background: url(../../images/fabrisorbdeco-ceiling-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product2-sub3 {
        background: url(../../images/fabrisorbdeco-ceiling-baffels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product2-sub4 {
        background: url(../../images/fabrisorbdeco-designer-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product3 {
        background: url(../../images/fabriwall-acoustic-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #product .product3-sub1 {
        background: url(../../images/fabriwall-track.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product3-sub2 {
        background: url(../../images/fabriwall-fabric.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product3-sub3 {
        background: url(../../images/fabriwall-panel.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product4 {
        background: url(../../images/acoustic-decopanel.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #product .product4-sub1 {
        background: url(../../images/decopanel-plain-colors.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product4-sub2 {
        background: url(../../images/decopanel-grooved-design.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product4-sub3 {
        background: url(../../images/decopanel-print-design.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product4-sub4 {
        background: url(../../images/decopanel-tiles.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product4-sub5 {
        background: url(../../images/decopanel-plain-print-rolls.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product4-sub6 {
        background: url(../../images/decopanel-baffles-clouds.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product5 {
        background: url(../../images/woodtec-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #product .product5-sub1{
        background: url(../../images/woodtec-linear-acoustic-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product5-sub2{
        background: url(../../images/woodtec-perforated-acoustic-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product5-sub3{
        background: url(../../images/woodtec-slotted-acoustic-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product5-sub4{
        background: url(../../images/woodtec-designer-acoustic-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product6 {
        background: url(../../images/echo-eliminator-acoustic-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #product .product6-sub1{
        background: url(../../images/Echo-Eliminator-four-4-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product6-sub2{
        background: url(../../images/Echo-Eliminator-nineteen-3-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product6-sub3{
        background: url(../../images/Echo-Eliminator-seventeen-1-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product6-sub4{
        background: url(../../images/Echo-eliminator-sixteen-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product6-sub5{
        background: url(../../images/Echo-Eliminator-ten-2-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product6-sub6{
        background: url(../../images/Echo-Eliminator-three-2-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product6-sub7{
        background: url(../../images/Echo-Eliminator-twelve-2-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product6-sub8{
        background: url(../../images/Echo-Eliminator-twenty-five-1-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product6-sub9{
        background: url(../../images/black_up_lg-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product6-sub10{
        background: url(../../images/burg_up_lg1-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product6-sub11{
        background: url(../../images/graphite_up_lg-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product6-sub12{
        background: url(../../images/green_up_lg1-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product6-sub13{
        background: url(../../images/light_gray_up_lg-1-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product6-sub14{
        background: url(../../images/marble_up_lg-200x200.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product7 {
        background: url(../../images/MLVB.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #product .product7-sub1{
        background: url(../../images/MLVB1.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product7-sub2{
        background: url(../../images/MLVB2.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product7-sub3{
        background: url(../../images/MLVB3.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product7-sub4{
        background: url(../../images/MLVB4.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product8 {
        background: url(../../images/eco-therm.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #product .product9 {
        background: url(../../images/deco-woodtec.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #product .product10 {
        background: url(../../images/education-acoustics.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #product .product11 {
        background: url(../../images/pyramid-pufoam.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #product .product11-sub1 {
        background: url(../../images/polyurethane-foam.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product11-sub2 {
        background: url(../../images/plain-pufoam.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product11-sub3 {
        background: url(../../images/cylinder-pufoam.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product11-sub4 {
        background: url(../../images/basstrap-pufoam.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product11-sub5 {
        background: url(../../images/eggcrate-pufoam.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product12 {
        background: url(../../images/prd-diffuso.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 500px;
    }
    #product .product13 {
        background: url(../../images/polymax-polyester-acoustic-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #product .product13-sub1 {
        background: url(../../images/polymax1.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product13-sub2 {
        background: url(../../images/polymax2.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product13-sub3 {
        background: url(../../images/polymax3.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product13-sub4 {
        background: url(../../images/polymax4.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product13-sub5 {
        background: url(../../images/polymax5.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product14 {
        background: url(../../images/polysorb.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #product .product14-sub1 {
        background: url(../../images/polysorb-wall.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product14-sub2 {
        background: url(../../images/polysorb-ceiling.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product14-sub3 {
        background: url(../../images/polysorb-baffles.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product14-sub4 {
        background: url(../../images/polysorb-designer-panels.webp
);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product15 {
        background: url(../../images/polysorb-deco.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #product .product15-sub1 {
        background: url(../../images/polysorbdeco-wall-panels.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product15-sub2 {
        background: url(../../images/polysorbdeco-ceiling-clouds.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .product15-sub3 {
        background: url(../../images/polysorbdeco-ceiling-baffles.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 260px;
    }
    #product .fade:not(.show) {
        opacity: 0;
        display: none;
    }
    #product .line-footer {
        background-color: #1d2122 !important;
        height: 1px !important;
        width: 100%;
        margin-top: 20px !important;
        margin-bottom: 24px !important;
        display: block !important;
    }
    #product .portfolio-container2 {
        visibility: visible;
        opacity: 1;
        padding: 0 0 0 0;
        position: relative;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: end;
    }
    #product .mob-product{
        display: none;
    }
    #product .section-gap1{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    #product .section-gap{
        display: block;
    }
@media only screen and (max-width: 767px){
    #product .mob-product{
        display: block;
        } 
    #product .section-gap1{
        display: block;
    }
    #product .section-gap{
        display: none;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px){
    #product .section-gap{
        display: none;
    }
    #product .mob-product{
        display: block;
        } 
    #product .section-gap1{
        display: block;
    }
}
#product .products-bg{
    background: url(../../images/our-products.webp) no-repeat;
}