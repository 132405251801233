  .moov-logo {
    background: url(../images/moovgroup.webp) no-repeat;
    width: 150px;
    height: 150px;
    background-size: 150px;
  }
  .text{
    font-size: 13px !important;
    text-align: left;
  }
  form .button{
    margin-top: 20px;
    line-height: 50px;
  }
  .mapouter{
    position:relative;
    text-align:right;
    height:200px;
    width:550px;
  }
  .gmap_canvas{
    overflow:hidden;
    background:none!important;
    height:510px;
    width:504px;
  }
  .single-footer-widget p{
    color: #828282;
  }