    #career .logo-box-style2 a img {
       width: 102px !important;
        height: 118px !important;
    }
    #career .top-bar-style2 {
        position: relative;
        display: block;
        background: #26272b;
        padding: 20px 26px !important;
    }
    #career .sec-title .title {
        position: relative;
        display: block;
        color: #fff;
        font-size: 35px;
        line-height: 44px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: 'Rubik', sans-serif;
    }
    #career .sidebar-title {
        position: relative;
        display: block;
        margin-top: -4px;
        padding-bottom: 8px;
    }
    #career .sidebar-title1 {
        position: relative;
        display: block;
        margin-top: -4px;
        padding-bottom: 8px;
    }
    #career .section-title {
        visibility: visible !important;
        opacity: 1 !important;
        padding: 0 0 0 10px !important;
        position: relative !important;
        left: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
    }
    #career .sidebar-title1:before {
        position: absolute;
        top: 10px;
        left: 0px;
        width: 10px;
        height: 5px;
        background: #e9212e;
        content: "";
    }
    #career .job-listing {
        max-width: 800px;
        margin: 0 auto;
        padding: 20px 20px 0 0;;
    }
    #career .job-card {
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 20px;
        padding: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    }
    #career .job-card h2 {
        margin-top: 0;
    }
    #career .job-card p {
        margin: 10px 0;
    }
    #career .job-listing .sec-title{
        position: relative;
        display: block;
        margin-top: 10px;
        padding-bottom: 10px;
    }
    #career .job-listing .sec-title p{
        padding-bottom: 10px;
        font-size: 20px;
    }
    #career .appointment-area{
        position: relative;
        display: block;
        background: #27282c;
        background-position: top right;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 110px;
        padding-bottom: 10px;
        z-index: 1;
    }
    #career .title:before {
        position: absolute;
        top: -16px;
        left: 0;
        width: 120px;
        height: 4px;
        background: #ffffff;
        content: "";
    }
    #career .title1{
        position: relative;
        display: block;
        color: #fff;
        font-size: 35px;
        line-height: 44px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: 'Rubik', sans-serif;
    }
    #career .fontweight{
        font-weight: 100;
    }
    span.title-tag {
        font-weight: 700 !important;
        font-size: 50px !important;
        letter-spacing: -0.03em !important;
        color: #e9212e !important;
        line-height: 1 !important;
        position: relative;
        left: 0 !important;
        top: 0 !important;
        padding-right: 20px;
    }
    #career .job-card ul {
        padding-left: 38px;
    }   
    #career .job-card ul li:before {
        font-family: "Flaticon";
        position: absolute;
        left: 42px;
        content: "\f102";
        color: #e9212e;
        font-size: 25px;    
    }
    #career .career-bg{
        background:url(../../images/we-are-hiring.webp) no-repeat;
        background-position: top right;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-size: cover;
    }
    #career .appointment-form .single-box{
        padding: 4px;
    }
    #career .appointment-form .single-box input{
        padding: 16px;
    }