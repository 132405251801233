    #event .logo-box-style2 a img {
        width: 102px !important;
        height: 118px !important;
    }
    #event .top-bar-style2 {
        position: relative;
        display: block;
        background: #26272b;
        padding: 20px 26px !important;
    }
    #event .breadcrumb-area .page-tag{
        font-size: 30px !important;
        font-family: 'Rubik', sans-serif !important;
        font-weight: 700 !important;
        color: #ffaa17 !important;
        letter-spacing: 2px !important;
    }
    #event .breadcrumb-area .page-title{
        font-size: 60px !important;
        line-height: 1.1 !important;
        color: #fff !important;
        text-transform: uppercase !important;
        letter-spacing: -1px !important;
        font-family: 'Rubik', sans-serif !important;
        font-weight: 700 !important;
    }
    #event .event-bg{
        background: url(../../images/recent-event.webp) no-repeat;
        background-attachment: scroll;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
    }