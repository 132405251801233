
#carousel .items {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    transition: all 0.2s;
    transform: scale(0.98);
    will-change: transform;
    user-select: none;
    cursor: pointer;
    } 
    
    
    
#carousel .item {
    display: inline-block;
    height: fit-content;
    margin: 1em 1em;
    animation: moov 20s linear infinite;
    }
    
    @keyframes moov {
        0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-200%);
          }
      }


  

#carousel .img-holder {
    border: 2px solid #fff;
    position: relative;
    display: block;
    overflow: hidden;
}
#carousel .img-holder:before {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 40%;
    content: "";
    transition: all 300ms ease 100ms;
    z-index: 2;
    background: rgb(39,40,44);
    background: linear-gradient(180deg, rgba(39,40,44,0) 40%, rgba(39,40,44,0.8407738095238095) 78%, rgba(39,40,44,1) 94%, rgba(39,40,44,1) 100%, rgba(39,40,44,1) 100%, rgba(237,237,237,1) 100%, rgba(32,50,96,0) 100%);
}
#carousel .title-box {
    position: absolute;
    left: 10px;
    bottom: 34px;
    z-index: 3;
    background-color: #00000078;
    width: 100%;
    padding: 20px;
}
#carousel .title-box h3 {
    color: #fff !important;
    text-transform: uppercase;
    font-size: 14px;
}
#carousel .title-box span {
    color: #c1862b !important;
    text-transform: uppercase;
    font-size: 12px;
}
#carousel .title-box h4 {
    color: #fff !important;
    text-transform: uppercase;
    font-size: 12px;
}
#carousel .title-box span {
    color: #c1862b !important;
    text-transform: uppercase;
    font-size: 12px;
}
#carousel .img-holder:after {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    content: "";
    opacity: 0;
    transition: all 300ms ease 100ms;
    z-index: 2;
    background: rgb(39,40,44);
    background: linear-gradient(0deg, rgba(39,40,44,0) 0%, rgba(39,40,44,0.8407738095238095) 0%, rgba(39,40,44,1) 0%, rgba(39,40,44,0.7959558823529411) 100%, rgba(39,40,44,1) 100%, rgba(39,40,44,1) 100%, rgba(237,237,237,1) 100%, rgba(32,50,96,0) 100%);
}