    #decowood .breadcrumb-section .page-tag {
        font-size: 30px;
    }
    #decowood .section-title{
        visibility: visible !important;
        opacity: 1 !important;
        padding: 0 0 0 100px !important;
        position: relative !important;
        left: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
    }
    #decowood .title-tag{
        font-family: "Rajdhani", sans-serif !important;
        font-weight: 700 !important;
        font-size: 60px !important;
        letter-spacing: -0.03em !important;
        color: #ffaa17 !important;
        line-height: 1 !important;
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
    }
    #decowood .line{
        background-color: #1d2122 !important;
        height: 1px !important;
        width: 170px !important;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        display: block !important;
    }
    #decowood .title1{
        font-size: 30px !important;
        letter-spacing: -0.03em !important;
        margin-bottom: 15px !important;
        text-transform: uppercase !important;
    }
    #decowood .logo-box-style2 a img {
        width: 102px !important;
        height: 118px !important;
    }
    #decowood .top-bar-style2 {
        position: relative;
        display: block;
        background: #26272b;
        padding: 20px 26px !important;
    }
    @media (min-width: 1200px){
        #decowood .col-xl-3 {
            flex: 0 0 35%;
            max-width: 30%;
        }
        #decowood .col-xl-9 {
            flex: 0 0 65%;
            max-width: 65%;
        }
        #decowood .col-xl-2{
            flex: 0 0 33.666667%;
            max-width: 30.666667%;
        }
    }
    #decowood .single-service-style1 .overlay-content-box .icon span:before{
        display: inline-block;
        color: #c1862b !important;
        font-size: 48px !important;
        line-height: 48px !important;
    }
    #decowood .single-service-style1 .text-holder .top .icon span:before{
        color: #d29929 !important;
        font-size: 30px !important;
        line-height: 40px !important;
    }
    #decowood .single-service-style1 .text-holder .bottom .title h3{
        color: #27282c;
        font-weight: 600;
        text-align: center;
    }
    #decowood .single-service-style1 .text-holder{
        padding: 35px 0px 28px !important;
    }
    #decowood .single-service-style1 .text-holder .bottom{
        padding-top: 7px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    #decowood .single-service-style1 .text-holder .overlay-content{
        padding: 6px 0 0 !important;
    }
    #decowood .single-blog-post .top-text-box{
        padding: 20px 0 0 0;
    }
    #decowood .blog-single-bottom-content-box{
        margin: 22px 0 30px 0 !important;
    }
    #decowood .single-sidebar .categories li a{
        line-height: 38px;
    }
    #decowood .product9 {
        background-image: url(../../images/deco-woodtec.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 420px;
    }
    #decowood .fade:not(.show) {
        opacity: 0;
        display: none;
    }
    #decowood .line-footer {
        background-color: #1d2122 !important;
        height: 1px !important;
        width: 100%;
        margin-top: 20px !important;
        margin-bottom: 24px !important;
        display: block !important;
    }
    #decowood .portfolio-container2 {
        visibility: visible;
        opacity: 1;
        padding: 0 0 0 0;
        position: relative;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: end;
    }
    #decowood .decowood-bg{
        background: url(../../images/our-products.webp) no-repeat;
    }