#home .working-area-bg{
  background: url('../../images/theater-bg.webp') no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center bottom;
}
#home .content-box-bg{
  /* background: url('../../images/Dubai.webp') no-repeat; */
  background: url('../../images/about_building.jpg') no-repeat;
  background-size: contain;
  margin-bottom: 30px;
}
#home .slogan-bg{
  background: url('../../images/home-cinema.webp') no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center bottom;
}
#home .why-bg{
  background: url('../../images/why-choose-bg.webp') no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center bottom;
}
#home .addcol{
  color: #c1862b !important;
}
#home .con-inner{
  padding-bottom: 100px;
}
#home .con-inner-box{
  width: 26%;
  text-align: center;
  color:#ffffff;
  margin: 30px 0px 30px 10px;
}
#home .con-inner-box-top{
  background: #5d6d7b !important;
  padding: 20px;
}
#home .con-inner-box-top h3{
  color: #ffffff !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
}
#home .con-inner-box-bot{
  background:#e9212e;
  padding: 20px;
}
#home .con-inner-box-bot p{
  margin-bottom: 0px;
  font-size: 13px;
}
#home .con-inner-box-top span:before
{
  color: #f6f6f6;
  font-size: 50px;
  line-height: 50px;
}
#home .con-inner-top{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
#home .con-inner-bot{
  display: flex;
  justify-content: flex-end;
}
#home .con-inner-bot-in{
  width: 60%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px){
  #home .con-inner-bot-in{
  width: 50%;
}
}
@media only screen and (min-width: 768px) and (max-width: 991px){
  #home .con-inner-bot-in{
  width: 100%;
  margin-top: 150px;
}
  #home .con-inner-box{
  width: 80%;
}
}
@media only screen and (max-width: 767px){
  #home .con-inner-top{
  flex-direction: column;
}
  #home .con-inner-box{
  width: 100%;
  margin: 30px 0px;
}
  #home .con-inner-bot-in{
  width: 100%;
}
}
@media only screen and (max-width: 479px){
  #home .main-slider.style2 .slide-content .text {
  padding: 14px 0 0 0;
  font-size: 12px !important;
  line-height: 1.4em;
}
  #home .main-slider.style2 .slide-content .big-title {
  font-size: 20px !important;
  line-height: 1.3em;
}
}

#home .banner-subtitle{
  font-size: 30px;
  color: #c1862b !important;
}
#home .about-style2-image-box .pattern{
  background: #5d6d7b !important;
  background-repeat: no-repeat;
}
#home .single-fact-counter .count-box h1{
  color: #c1862b !important;
}
#home .single-fact-counter .title h3{
      color: #e9212e !important;
}
#home .slogan-style2-area{
  padding: 50px 0 62px !important;
}
#home .single-service-style1 .text-holder .bottom .title {
  text-transform: uppercase;
}
#home .about-style2-text .text1{
  border-top: 2px dashed #fff !important;
}
#home .sec-title .title2{
  position: relative;
  display: block;
  color: #27282c;
  font-size: 35px;
  line-height: 44px;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(45deg, rgba(65,94,82,1) 0%, rgba(255,0,0,1) 1%, rgba(77,0,0,1) 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-family: 'Rubik', sans-serif !important;
}
#home .sec-title .bottom-text{
  font-size: 16px !important;
  text-transform: uppercase !important;
  color: #c1862b !important;
}
#home .sec-title .title.clr-white {
  color: #ffffff;
  font-size: 18px !important;
}
#home .services-style1-area{
  padding: 36px 0 80px !important;
}
#home .recently-project-style2-area{
  background-color: #24334a !important;
}
#home .sec-title .pro-title {
  position: relative;
  display: block;
  color: #fff;
  font-size: 35px;
  line-height: 44px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Rubik', sans-serif;
}
#home .single-project-style2 .img-holder{
  border: 2px solid #fff;
}
#home .company-overview-area{
  padding: 105px 0 76px !important;
}
#home .contact-details-area{
  padding: 10px 0 0 !important;
}
#home .text1{
  font-size: 16px !important;
  text-align: justify;
  padding-top: 30px;
}
#home .single-working-box .text-holder .outer-box .text h3{
  text-transform: uppercase;
}
#home .slogan-style2-area .inner-content .button a:hover{
  background: #27282c !important;
}

#home .title-pioneer{
  text-align: center;
  color: #000 !important;
}
#home .year{
      margin-left: 9px;
      font-size: 20px;
      font-weight: bold;
      color: #555555;
}
#home .fifteen{
  margin-bottom: -10px;
}


#home .company-overview-area:before{
  background: #ffffff;
  height: 480px;
}
#home .fact-counter {
  padding-top: 50px !important;
}
#home .banner1{
  width:40%; 
  float:left;
}
#home .banner2{
  width: 60%; 
  float: left; 
  opacity: 0; 
  visibility: hidden; 
  z-index: 20;
  background-image: url('../../images/ferrari-world-abu-dhabi-moov-group.webp');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home .banner3{
  width: 60%; 
  float: left; 
  opacity: 0; 
  visibility: hidden; 
  z-index: 20;
  background-image: url('../../images/kapsarc-moov-group.webp');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home .banner4{
  width: 60%; 
  float: left; 
  opacity: 0; 
  visibility: hidden; 
  z-index: 20;
  background-image: url('../../images/presidential-palace-abu-dhabi-moov-group.webp');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home .banner5{
  width: 60%; 
  float: left; 
  opacity: 0; 
  visibility: hidden; 
  z-index: 20;
  background-image: url('../../images/Louvre-Abu-Dhabi-moov-group.webp');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home .banner6{
  width: 60%; 
  float: left; 
  opacity: 0; 
  visibility: hidden; 
  z-index: 20;
  background-image: url('../../images/Dubai-Airport-Terminal-2-moov-group.webp');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home .banner7{
  width: 60%; 
  float: left; 
  opacity: 0; 
  visibility: hidden; 
  z-index: 20;
  background-image: url('../../images/Zayed-sports-complex-moov-group.webp');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home .banner8{
  width: 60%; 
  float: left; 
  opacity: 0; 
  visibility: hidden; 
  z-index: 20;
  background-image: url('../../images/Rashid-Library-Dubai-moov-group.webp');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home .banner9{
  width: 60%; 
  float: left; 
  opacity: 0; 
  visibility: hidden; 
  z-index: 20;
  background-image: url('../../images/museum-of-future-dubai-moov-group.webp');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home .banner10{
  width: 60%; 
  float: left; 
  opacity: 0; 
  visibility: hidden; 
  z-index: 20;
  background-image: url('../../images/Auditorium-AbuDhabi-Port-Authority-moov-group.webp');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home .banner11{
  width: 60%; 
  float: left; 
  opacity: 0; 
  visibility: hidden; 
  z-index: 20;
  background-image: url('../../images/Vox-Sharjah-moov-group.webp');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home .banner-content{
  padding-bottom:20px;
  width:75%;
}
#home .banner-content-des{
  padding-top:86px;
  padding-bottom:40px;
  width:75%;
}
#home .banner-content-btn{
  padding-top:130px;
  padding-bottom:40px;
  width:75%;
}
#home .slogan-style2-area .inner-content .button a.call-us{
  padding-left: 76px !important;
  padding-right: 76px !important;
  font-size: 18px !important;
}
#home .single-project-style2 .title-box span{
  color: #c1862b !important;
  text-transform: uppercase;
  font-size: 12px;
}
#home .single-project-style2 .title-box h4{
  color: #fff !important;
  text-transform: uppercase;
  font-size: 12px;
}
#home .single-project-style2 .title-box h3{
  color: #fff !important;
  text-transform: uppercase;
  font-size: 14px;
}
#home .single-project-style2 .title-box {
  position: absolute;
  left: 10px;
  bottom: 34px;
  z-index: 3;
  background-color: #00000078;
  width: 100%;
  padding: 20px;
}
#home .inner img {
  width: 570px;
  height: 370px;
}

#home .sec-title .title3{
  position: relative;
  display: block;
  color: #27282c;
  font-size: 14px;
  line-height: 44px;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(45deg, rgba(65,94,82,1) 0%, rgba(255,0,0,1) 1%, rgba(77,0,0,1) 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  font-family: 'Rubik', sans-serif !important;
}
#home .single-service-style1 .text-holder .overlay-content .read-more{
  top: 40px !important;
  position: relative !important;
}
#home .single-service-style1 .text-holder .top .icon span:before{
  color: #d29929 !important;
  font-size: 30px !important;
  line-height: 40px !important;
}
#home .single-service-style1 .overlay-content-box .icon span:before {
  display: inline-block;
  color: #c1862b !important;
  font-size: 48px !important;
  line-height: 48px !important;
}
#home .about-style2-image-box .image {
  position: relative;
  display: block;
  max-width: 670px;
  width: 100%;
  margin: 0 auto;
  z-index: 3;
  background-image: url('../../images/moovgroup-office.webp');
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: -9px 7px 20px 4px #212529;
  height: 890px;
  background-position: -238px 0px;
}
#home .about-style2-text {
  position: relative;
  display: block;
  margin: 12px;
}
#home .logo-box-style2 a img {
  width: 102px !important;
  height: 118px !important;
}
#home .top-bar-style2 {
  position: relative;
  display: block;
  background: #26272b;
  padding: 20px 26px !important;
}
@media only screen and (max-width: 479px){
  #home .banner1{
  display:none;
}
#home .banner2{
  width: 100% !important;
}
#home .banner3{
  width: 100% !important;
}
#home .banner4{
  width: 100% !important;
}
#home .banner5{
  width: 100% !important;
}
#home .banner6{
  width: 100% !important;
}
#home .banner7{
  width: 100% !important;
}
#home .banner8{
  width: 100% !important;
}
#home .banner9{
  width: 100% !important;
}
#home .banner10{
  width: 100% !important;
}
#home .banner11{
  width: 100% !important;
}
#home .banner-content-btn {
  padding-top: 140px;
  padding-bottom: 10px;
  width: 100%;
}
#home .content-background{
  background-color: #0000005c;
  height: 100%;
  width: 100%;
  position: absolute;
}
#home .about-style2-area {
  position: relative;
  display: block;
  padding: 60px 0 110px !important;
}
#home .banner-content-des{
  padding-top: 96px;
  padding-bottom: 40px;
  width: 100%;
}
}
@media only screen and (max-width: 767px){
  #home .about-style2-image-box {
  margin-top: 105px !important;
}
  #home .about-style2-image-box .overlay-box .button {
  display: none !important;
}
}


#home .sprite-img {
display: block;
text-indent: -999px;
}
#home .architectural{
background: url('../../images/sectors.webp')-8px -12px no-repeat;
width: 370px;
height: 370px;
margin: 0px auto;
background-size: 1144px auto;
}
#home .auditorium{
background: url('../../images/sectors.webp')-388px -12px no-repeat;
width: 370px;
height: 370px;
margin: 0px auto;
background-size: 1144px auto;
}
#home .themepark{
background: url('../../images/sectors.webp')-766px -12px no-repeat;
width: 370px;
height: 370px;
margin: 0px auto;
background-size: 1144px auto;
}
#home .studio{
background: url('../../images/sectors.webp')-8px -394px no-repeat;
width: 370px;
height: 370px;
margin: 0px auto;
background-size: 1144px auto;
}
#home .education{
background: url('../../images/sectors.webp')-388px -394px no-repeat;
width: 370px;
height: 370px;
margin: 0px auto;
background-size: 1144px auto;
}
#home .hometheatre{
background: url('../../images/sectors.webp')-766px -394px no-repeat;
width: 370px;
height: 370px;
margin: 0px auto;
background-size: 1144px auto;
}
#home .fabrisorb{
background: url('../../images/products.webp')-4px -2px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .fabrisorb-deco{
background: url('../../images/products.webp')-192px -2px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .fabriwall{
background: url('../../images/products.webp')-380px -2px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .decopanel{
background: url('../../images/products.webp')-566px -2px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .woodtec{
background: url('../../images/products.webp')-754px -2px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .echo-eliminator{
background: url('../../images/products.webp')-4px -228px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .mlvb{
background: url('../../images/products.webp')-192px -228px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .eco-therm{
background: url('../../images/products.webp')-380px -228px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .deco-woodtec{
background: url('../../images/products.webp')-566px -228px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .acoustic-doors{
background: url('../../images/products.webp')-754px -228px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .pufoam{
background: url('../../images/products.webp')-4px -452px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .diffuso{
background: url('../../images/products.webp')-192px -452px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .polymax{
background: url('../../images/products.webp')-380px -452px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .polysorb{
background: url('../../images/products.webp')-566px -452px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .polysorb-deco{
background: url('../../images/products.webp')-754px -228px no-repeat;
width: 170px;
height: 214px;
margin: 0px auto;
background-size: 930px auto;
}
#home .yas-arena {
background: url('../../images/projects.webp') -18px -16px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .quran-radio-studio{
background: url('../../images/projects.webp') -444px -16px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .german-pavilion{
background: url('../../images/projects.webp') -866px -16px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .direct-energy{
background: url('../../images/projects.webp') -18px -494px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .emirati-school{
background: url('../../images/projects.webp') -444px -494px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .ferrari-world{
background: url('../../images/projects.webp') -866px -494px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .kasparc-project{
background: url('../../images/projects.webp') -18px -972px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .presidential-palace{
background: url('../../images/projects.webp') -444px -972px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .skynews-project{
background: url('../../images/projects.webp') -866px -972px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .eibf-project{
background: url('../../images/projects.webp') -18px -1450px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .lauvre-project{
background: url('../../images/projects.webp') -444px -1450px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .nba-project{
background: url('../../images/projects.webp') -866px -1450px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .dubai-airport{
background: url('../../images/projects.webp') -18px -1928px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .vox-project{
background: url('../../images/projects.webp') -444px -1928px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .sports-complex{
background: url('../../images/projects.webp') -866px -1928px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .novo-project{
background: url('../../images/projects.webp') -18px -2406px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .novo-img-project{
background: url('../../images/projects.webp') -444px -2406px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .azam-tv-project{
background: url('../../images/projects.webp') -866px -2406px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .dubai-parks{
background: url('../../images/projects.webp') -18px -2884px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
#home .emal-project{
background: url('../../images/projects.webp') -444px -2884px no-repeat;
width: 382px;
height: 434px;
margin: 0px auto;
background-size: 1282px auto;
}
@media only screen and (max-width: 479px){
  .single-service-style1 .text-holder{
    margin: auto 70px;
  }
}

#moov .contact-details-form input[type="text"], .contact-details-form input[type="email"], .contact-details-form textarea{
  line-height: 52px;
}