    #project .breadcrumb-section .page-tag {
        font-size: 30px;
    }

    #project .line {
        background-color: #1d2122 !important;
        height: 1px !important;
        width: 170px !important;
        margin-top: 20px !important;
        margin-bottom: 24px !important;
        display: block !important;
    }

    #project .line-footer {
        background-color: #1d2122 !important;
        height: 1px !important;
        width: 100%;
        margin-top: 20px !important;
        margin-bottom: 24px !important;
        display: block !important;
    }

    #project .portfolio-next {
        line-height: 1;
        text-decoration: none;
        font-size: 16px;
        padding: 20px 56px;
        border: 2px solid #e5e8ea;
        color: #1d2122;
        font-weight: 700;
        font-family: 'Rubik', sans-serif;
        margin-top: 30px;
    }

    #project .portfolio-next:hover {
        background-color: #ffaa17;
        border-color: #ffaa17;
    }

    #project .portfolio-container1 {
        visibility: visible;
        opacity: 1;
        padding: 0 0 0 0;
        position: relative;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: start;
    }

    #project .portfolio-container2 {
        visibility: visible;
        opacity: 1;
        padding: 0 0 0 0;
        position: relative;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: end;
    }

    #project .portfolio-previous {
        line-height: 1;
        text-decoration: none;
        font-size: 16px;
        padding: 20px 40px;
        border: 2px solid #e5e8ea;
        color: #1d2122;
        font-weight: 700;
        font-family: 'Rubik', sans-serif;
        margin-top: 30px;
    }

    #project .portfolio-previous:hover {
        background-color: #ffaa17;
        border-color: #ffaa17;
    }

    #project .logo-box-style2 a img {
        width: 102px !important;
        height: 118px !important;
    }

    #project .top-bar-style2 {
        position: relative;
        display: block;
        background: #26272b;
        padding: 20px 26px !important;
    }

    #project .portfolio-link {
        display: none;
    }

    #project .line-footer1 {
        background-color: #1d21226b !important;
        height: 1px !important;
        width: 100%;
        margin-top: 20px !important;
        margin-bottom: 24px !important;
        display: block !important;
    }

    #project .portfolio-thumb {
        display: none;
    }

    @media only screen and (max-width: 479px) {
        #project .portfolio-container1 {
            text-align: center !important;
        }

        #project .portfolio-container2 {
            text-align: center !important;
        }

        #project .portfolio-link {
            display: block;
        }

        #project .portfolio-thumb {
            display: block;
        }

        #project .gallery {
            display: none !important;
        }

        #project .line-footer1 {
            display: none !important;
        }
    }

    #project .gallery {
        display: grid;
        grid-gap: 15px;
    }

    #project .img-bot {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        width: 100%;
    }

    #project .temple-pro1 {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/moov-pro1.jpeg) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .temple-pro2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/moov-proj1.jpeg) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .temple-pro3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/moov-proje1.jpeg) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .gems-pro1 {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/gems1.jpeg) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .gems-pro2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/gems2.jpeg) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .gems-pro3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/gems3.jpeg) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .emarati-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/emirati-school.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .emarati-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/emirati-school1.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .emarati-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/emirati-school2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .voxbahrain-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/voxcinemas-bahrain.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .voxbahrain-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/voxcinemas-bahrain2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .voxbahrain-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/voxcinemas-bahrain3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .futuremuseum-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/museum-of-future-dubai-moov-group.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .futuremuseum-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/future-museum1.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .futuremuseum-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/future-museum2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .beeah-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/beeah.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .beeah-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/beeah2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .beeah-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/beeah3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .rashid-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/rashid-library.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .rashid-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/rashid-library1.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .rashid-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/rashid-library2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .louvre-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/Louvre-Abu-Dhabi-moov-group.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .louvre-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/louvre2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .louvre-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/louvre3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .nbad-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/nbad.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .nbad-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/nbad2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .nbad-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/nbad3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .dubaiairport-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/Dubai-Airport-Terminal-2-moov-group.webp) no-repeat;
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .dubaiairport-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/dubai-airport2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .dubaiairport-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/dubai-airport3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .voxsharjah-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/Vox-Sharjah-moov-group.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .voxsharjah-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/Vox-Sharjah2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .voxsharjah-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/Vox-Sharjah3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .sportscomplex-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/Zayed-sports-complex-moov-group.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .sportscomplex-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/Zayed-sports-complex1.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .sportscomplex-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/Zayed-sports-complex2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .primaryschools-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/primary-schools.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .primaryschools-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/primary-schools2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .primaryschools-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/primary-schools3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .novocinemas-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/novo-cinemas.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .novocinemas-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/novo-cinemas2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .novocinemas-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/novo-cinemas3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .novocinemasimg-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/novo-img.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .novocinemasimg-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/novo-img2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .novocinemasimg-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/novo-img3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .azamtv-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/azam-tv.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .azamtv-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/azam-tv2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .azamtv-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/azam-tv3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .dubaiparks-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/theme-park-acoustics.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .dubaiparks-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/theme-park-acoustics2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .dubaiparks-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/theme-park-acoustics3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .privatehomecinema-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/private-homecinema.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .privatehomecinema-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/private-homecinema2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .privatehomecinema-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/private-homecinema3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .orbitheatre-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/orbi-theatre.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .orbitheatre-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/orbi-theatre2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .orbitheatre-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/orbi-theatre3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .abudhabiport-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/auditorium-acoustics.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .abudhabiport-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/abudhabi-port2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .abudhabiport-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/abudhabi-port3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .emal-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/emal.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .emal-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/emal2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .emal-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/emal2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .africahall-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/architectural-acoustics.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .africahall-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/africahall2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .africahall-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/africahall3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .culturalcenter-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/fujairah-cultural-center2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .culturalcenter-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/fujairah-cultural-center.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .culturalcenter-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/fujairah-cultural-center3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .kalbatv-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/kalba-tv.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .kalbatv-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/kalba-tv2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .kalbatv-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/kalba-tv3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .khalifauniversity-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/khalifa-university.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .khalifauniversity-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/khalifa-university2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .khalifauniversity-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/khalifa-university3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .thumbayinstitute-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/thumbay-group.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .thumbayinstitute-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/thumbay-group2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .thumbayinstitute-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/thumbay-group3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .americanschool-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/american-school.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .americanschool-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/american-school2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .americanschool-img3 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/american-school3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .etisalatauditorium-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/etisalat-auditorium.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .etisalatauditorium-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/etisalat-auditorium2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .etisalatauditorium-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/etisalat-auditorium3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .dancefm-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/dancefm.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .dancefm-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/dancefm2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .dancefm-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/dancefm3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .whotel-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/whotel3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .whotel-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/Hotel_2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .whotel-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/Hotel_3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .hiltonhotel-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/hiltonhotel.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .hiltonhotel-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/hiltonhotel2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .hiltonhotel-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/hiltonhotel3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .utc-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/New_Pro_imgs/UTC_1.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .utc-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/New_Pro_imgs/UTC_2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .utc-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/New_Pro_imgs/UTC_3.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .ferrari-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/ferrari-world-abu-dhabi-moov-group.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .ferrari-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/ferrari-world1.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .ferrari-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/ferrari-world2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .kapsarc-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/kapsarc-moov-group.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .kapsarc-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/kapsarc1.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .kapsarc-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/kapsarc2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .presidential-palace-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/presidential-palace-abu-dhabi-moov-group.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .presidential-palace-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/presidential-palace1.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .presidential-palace-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/presidential-palace2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .skynews-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        background: url(../../images/skynews.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .skynews-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/skynews1.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .skynews-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/skynews2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .eibf-img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        /* background: url(../../images/eibfs.webp) no-repeat; */
        background: url(../../images/EMIRATES_1.png) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .eibf-img2 {
        width: 49%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/eibfs1.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .eibf-img3 {
        width: 48%;
        height: 220px;
        object-fit: cover;
        background: url(../../images/eibfs2.webp) no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: solid #ffaa17 2px;
    }

    #project .projects-bg {
        background: url(../../images/recently-completed-project.webp) no-repeat;
        background-size: cover;
        background-position: center;
    }

    #project .recently-project-style2-area {
        background-color: #24334a !important;
    }

    #project .sec-title .pro-title {
        position: relative;
        display: block;
        color: #fff;
        font-size: 35px;
        line-height: 44px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: 'Rubik', sans-serif;
    }

    #project .bottom-text {
        font-size: 16px !important;
        text-transform: uppercase !important;
        color: #c1862b !important;
    }

    #portsub .portfolio-thumb {
        display: block;
    }

    #portsub img {
        width: 600px;
        height: 600px;
    }

    .about-content {
        text-align: justify;
    }

    .scope-content {
        text-align: justify;
    }